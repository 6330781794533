body{
    margin: 0px;
    height: 100%;
    overflow: hidden;
}
// ---- fondo Transparente ----------------------------------

$fondo : transparent;


// ---- Estilos del contenedor del Item ---------------------

.c-anim{
	
	width: 100%;
	height: auto;
	z-index: 2;
	padding: 100px 0px;
	
	&--1{
        position: absolute;
        float: left;
		background-color: $fondo;
	}
}

// ---- Ocultamos el div del Json ---------------------------

.u-none{ display: none!important; }