@import "../../scss/index.scss";
/* Este background trae imagenes aleatorias, era para hacer las pruebas de loading */
//background: url("https://source.unsplash.com/random/4000x4000") center no-repeat;

/* Estilos de contenedor principal, aqui solo se configura pantalla completa*/
.principal {
    width: 100%;
    height: 100vh;
    min-height: 800px;
    /* Estilos de contenedor, aqui solo se configura pantalla completa y se calcula el tamaño restando el tamaño del footer y se asigna color de fondo*/
    .contenedor {
        width: 100%;
        height: calc(100% - 64px);
        background: $primary-color;
        /* Estilos del fondo, se asigna la imagen de Giza y la ajustamos*/
        .fondo {
            display: flex;
            justify-content: center;
            align-items: center;
            background: url("../../img/Giza-Recargas-Web.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            box-shadow: 0px 0px 10px 5px black;
            width: 100%;
            height: 100%;
            flex-direction: column;
            /* Estilos del logo, solo se pone el tamaño de la imagen y un margen para que no se pegue con el form*/
            .logo {
                width: 400px;
                height: 200px;
                margin-bottom: 2%;
            }
            .loginForm {
                /* Estilos del icono de mostrar / ocultar contraseña*/
                #icoPass {
                    float: right;
                    margin-right: 10px;
                    margin-top: -30px;
                    position: relative;
                    background: transparent;
                    border-style: hidden;
                }
                /* Estilos del Form*/
                width: 25rem;
                padding: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color: $secondary-color;
                margin-top: 1em;
                border-radius: 5%;
                box-shadow: 0px 5px 20px -5px $color-hover;
                /* Estilos de los input User y Pass, solo asigno un color de borde*/
                .form-group {
                    .form-control {
                        border-width: 3px;
                        border-style: solid;
                        border-color: $secondary-color;
                        text-align: center;
                        border-radius: 20px;
                    }
                }
                /* Estilos del boton Entrar, solo se asigna color del boton*/
                .botones {
                    display: flex;
                    justify-content: center;
                    .btnEntrar {
                        position: relative;
                        width: 100%;
                        padding: 5px;
                        font-size: 1em;
                        font-weight: bold;
                        margin-top: 10%;
                        background: $primary-color;
                        color: #fff;
                        text-align: center;
                        border-radius: 50px;
                        border-color: transparent;
                    }
                    .btnEntrar:hover {
                        color: white;
                        box-shadow: 0 4px 8px $primary-color;
                        transition: all 0.2s ease;
                    }
                }
            }
        }
    }

    /* Estilos del Footer*/
    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        background: $primary-color;
        color: white;
        width: 100wh;
        h5{
            margin: 20px;
        }
    }
}
/* Estilos Media Query cuando el tamaño de pantalla es meximo de 576 pixeles*/
@media screen and (max-width: 576px) {
    .contenedor {
        .fondo {
            /* Se cambia el tamaño del logo mas pequeño*/
            .logo {
                width: 200px !important;
                height: 100px !important;
                margin-bottom: 2%;
            }
            /* Se hacen mas pequeños los botones e inputs del formulario */
            .loginForm {
                width: 85% !important;
                font-size: 15px !important;
                padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color: $secondary-color;
                margin-top: 1em;
                border-radius: 50%;
            }
            /* Se cambia el tamaño de footer */
            .footer {
                background: $primary-color;
                color: white;
                width: 100%;
                position: fixed;
            }
        }
    }
}
