//colores principales
$primary-color: #ff6251;
$secondary-color: #404681;
$color-hover: #494e8c;

//colores para las letras
$font-light: #ffffff;
$font-black: #000000;

//verificar login*****
$font-grey: #9e9e9e;
$font-dark: #0a0a0a;

//colores de fondo
$background-dark: #0a0a0a;
$background-light: #ffffff;
$background-principal: #f0f0f0;

//verificar menulateral*******
$background-light-grey: #bfbfbf;
$background-grey-hover: #9e9e9e;

//colores advertencias modales
$colorCorrecto: #67ac5b;
$colorIncorrecto: #c4281c;
$colorAdvertencia: #f6c244;

//bordes
$borderLight: #d4d4d5;
$grey-color: #9e9e9e;

//desabled
$background-disabled: #8488ac;
