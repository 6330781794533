@import "../../scss/index.scss";
/* Estilos de contenedor principal, toma la pantalla completa*/
.main-content {
    width: 100%;
    height: 100vh;
    min-height: 800px;
    /* Estilos del contenedor de imagen de fondo, logo y form, calcula el tamaño menos, el tamaño del footer y fondo*/
    .contenedorActivaciones {
        width: 100%;
        height: calc(100% - 64px);
        background: $primary-color;
        /* Estilos del fondo de pantalla, y centrado del form y el logo */
        .fondoActivaciones {
            background-color: rgba($color: #0f0000, $alpha: 0.7);
            box-shadow: 0px 0px 10px 5px black;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url("../../img/Giza-Recargas-Web.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            width: 100%;
            height: 100%;
            flex-direction: column;
            /* Tamaño del logo */
            .logo {
                width: 320px;
                height: 120px;
                margin-top: 2%;
                margin-bottom: 2%;
            }
            .loginFormActivaciones {
                border-radius: 20px;
            }
            /* Estilos del Form, tamaño del form y color de texto*/
            .activacionesForm {
                width: 23rem;
                padding: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color: $secondary-color;
                margin-top: 1em;
                /* Estilo de los input, color de borde del input  */
                .form-control {
                    border-width: 3px;
                    border-style: solid;
                    border-color: $color-hover;
                    width: 250px;
                    margin-left: 9%;
                    text-align: center;
                    border-radius: 20px;
                }
                /* Estilo de los label, se ajusta el texto enmedio  */
                .form-label {
                    margin-left: 9%;
                }
                /* Estilo del boton, color y tamaño  */
                .btnRecargar {
                    margin-top: 10%;
                    background: $primary-color;
                    color: #fff;
                    text-align: center;
                    padding: 5px;
                    font-size: 1em;
                    width: 250px;
                    margin-left: 9%;
                    border-radius: 50px;
                    border-color: transparent;
                    text-align: center;
                    font-weight: bold;
                }

                .btnRecargar:hover {
                    color: rgba(255, 255, 255, 1);
                    box-shadow: 0 4px 8px $primary-color;
                    transition: all 0.2s ease;
                }
                /* Estilo del boton Cerrar Sesion */
                .btnCerrar {
                    background: $secondary-color;
                    color: #fff;
                    margin-top: 20px;
                    padding: 5px;
                    font-size: 1em;
                    width: 250px;
                    margin-left: 9%;
                    border-radius: 50px;
                    text-align: center;
                    border-color: transparent;
                    font-weight: bold;
                }
                .btnCerrar:hover {
                    color: rgba(255, 255, 255, 1);
                    box-shadow: 0 4px 8px $color-hover;
                    transition: all 0.2s ease;
                }
            }
        }
    }
    /* Estilo del footer, color y tamaño */
    .footerPrincipal {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        background: $primary-color;
        color: white;
        width: 100wh;
        h5 {
            margin: 20px;
        }
    }
}

/* media Query cuando las pantallas son de tamaño maximo 576 pixeles  */
@media screen and (max-width: 576px) {
    /* Tamaño del contenedor*/
    .contenedorActivaciones {
        height: 100% !important;
        /*Logo mas pequeño*/
        .fondoActivaciones {
            .logo {
                width: 200px !important;
                height: 100px !important;
                margin-bottom: 2%;
            }
            /* Margines en la pantalla y centrado de items del form  */
            .loginFormActivaciones {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 85% !important;
            }
            /* Estilos del Form */
            .activacionesForm {
                padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color: $secondary-color;
                margin-top: 1em;
                border-radius: 50%;
                /* disminuimos tamaño del Boton Recargar y ajustamos enmedio*/
                .btnRecargar {
                    margin-top: 10%;
                    background: $primary-color;
                    color: #fff;
                    text-align: center;
                    width: 250px;
                    margin-left: 12%;
                }
                /* disminuimos tamaño del Boton Cerrar Sesion y ajustamos enmedio*/
                .btnCerrar {
                    background: #000000;
                    color: #fff;
                    margin-top: 20px;
                    width: 250px;
                }
            }
            /* Ajustamos el tamaño del footer*/
            .footerPrincipal {
                background: $primary-color;
                color: white;
                width: 100%;
                height: 100px;
                position: fixed;
            }
        }
    }
}
